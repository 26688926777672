<template>
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('platform.register-title') }}</h3>
        <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
      </div>
      <!--/title-sort-box-->
      <div class="table-horizon st01">
        <ValidationObserver ref="form">
          <form id="InsertForm" enctype="multipart/form-data">
            <table class="form-md">
              <colgroup>
                <col style="width:10%" />
                <col style="width:37%" />
                <col style="width:16%" />
                <col style="width:37%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('platform.label-serviceId')
                    }}</label>
                  </th>
                  <td>
                    <span class="isf-with">
                      <text-input
                        :rules="{
                          required: true,
                          regex: /[0-9A-Za-z\-_]{4,20}/,
                        }"
                        label="Service ID"
                        name="srvcId"
                        maxlength="20"
                        v-model.trim="formData.srvcId"
                        :placeholder="placeholder.max10"
                      />
                      <button
                        type="button"
                        class="bt-md bt-default"
                        @click="checkServiceIdValidate"
                      >
                        {{ $t('platform.btn-check-serviceId') }}
                      </button>
                    </span>
                  </td>
                  <th>
                    <label class="ic-star">{{
                      $t('platform.label-serviceName')
                    }}</label>
                  </th>
                  <td>
                    <text-input
                      :rules="{
                        required: true,
                        regex: /[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_-]{1,30}/,
                      }"
                      label="Service Name"
                      name="srvcNm"
                      maxlength="50"
                      v-model.trim="formData.srvcNm"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('platform.label-redirectUri')
                    }}</label>
                  </th>
                  <td>
                    <text-input
                      :rules="{
                        required: true,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      label="Redirect URI"
                      name="webServerRedirectUri"
                      maxlength="200"
                      :placeholder="placeholder.url"
                      dataType="URL"
                      v-model.trim="formData.webServerRedirectUri"
                    />
                  </td>

                  <th>
                    <label>{{ $t('platform.label-company') }}</label>
                  </th>
                  <td>
                    <text-input
                      label="Company Name"
                      name="cmpnyNm"
                      maxlength="25"
                      v-model.trim="formData.cmpnyNm"
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('platform.label-adminTel')
                    }}</label>
                  </th>
                  <td>
                    <text-input
                      :rules="{
                        required: true,
                        regex: /^[0][0-9]{7,10}$/,
                      }"
                      label="Admin Telephone"
                      name="chargerTelno"
                      maxlength="11"
                      :placeholder="placeholder.onlyNum"
                      dataType="NUMBER"
                      v-model.trim="formData.chargerTelno"
                    />
                  </td>

                  <th>
                    <label class="ic-star">{{
                      $t('platform.label-adminEmail')
                    }}</label>
                  </th>
                  <td>
                    <text-input
                      :rules="{
                        required: true,
                        regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                      }"
                      label="Admin Email"
                      name="chargerEmail"
                      maxlength="50"
                      dataType="EMAIL"
                      v-model.trim="formData.chargerEmail"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>{{ $t('platform.label-contents') }}</label>
                  </th>
                  <td colspan="3">
                    <text-input
                      label="Contents"
                      name="dc"
                      maxlength="200"
                      type="textarea"
                      v-model="formData.dc"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </ValidationObserver>
      </div>
      <div class="btns-bottom">
        <button type="button" class="bt-md bt-default" @click="resetForm">
          <span>{{ $t('common.btn-cancel') }}</span>
        </button>
        <button type="button" class="bt-md bt-red" @click="checkValidate">
          <span>{{ $t('common.btn-submit') }}</span>
        </button>
      </div>
    </div>
    <!--/contents-->
  </div>
  <!--/container-->
</template>
<script>
import TextInput from '@/components/common/TextInput.vue';
import { serviceIdDuplcation, insertPlatform } from '@/api/requestApi.js';
export default {
  components: { TextInput },
  data() {
    return {
      pathList: [],
      title: {},
      placeholder: {},
      formData: {
        srvcId: '',
        srvcNm: '',
        webServerRedirectUri: '',
        cmpnyNm: '',
        chargerTelno: '',
        chargerEmail: '',
        dc: '',
        mberRegistNtcnUrl: '',
        srvcConfmNtcnUrl: '',
      },
      srvidDuplicate: 'N',
    };
  },
  created() {
    this.initLocale();
  },
  methods: {
    // 메뉴, placeholder 등 문구 set
    initLocale() {
      this.pathList.push(this.$i18n.t('menu.home'));
      this.pathList.push(this.$i18n.t('menu.solutions'));
      this.pathList.push(this.$i18n.t('subMenu.platform'));

      this.title.mainTitle = this.$i18n.t('menu.solutions');
      this.title.smallTitle = this.$i18n.t('menu.solutions-eng');

      this.placeholder.url = this.$i18n.t('common.placeholder.url-format');
      this.placeholder.max10 = this.$i18n.t(
        'common.placeholder.id-format-max',
        { max: 20 },
      );
      this.placeholder.onlyNum = this.$i18n.t(
        'common.placeholder.tel-only-num',
      );
    },

    // 서비스 아이디 유효성 검사
    checkServiceIdValidate() {
      if (this.formData.srvcId === '') {
        this.$dialogs.alert(this.$i18n.t('platform.alert-empty-srvcId'));
        return;
      } else if (this.formData.srvcId.length < 4) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert-textLenth-invalid', {
            min: 4,
            max: 20,
            field: this.$i18n.t('platform.label-serviceId'),
          }),
        );
        return;
      } else {
        this.serviceIdDuplcation();
      }
    },
    // 서비스 아이디 중복확인
    async serviceIdDuplcation() {
      let srvcId = this.formData.srvcId;
      const {
        data: { valid },
      } = await serviceIdDuplcation({ srvcId });
      if (valid) {
        this.$dialogs.alert(this.$i18n.t('platform.alert-id-valid'));
        this.srvidDuplicate = 'Y';
      } else {
        this.$dialogs.alert(this.$i18n.t('platform.alert-id-dupl'));
        this.srvidDuplicate = 'N';
      }
    },
    // 페이지 유효성 검사
    checkValidate() {
      this.$refs.form.validate().then(res => {
        if (!res) {
          this.$dialogs.alert(this.$i18n.t('common.invalid-required'));
          return;
        }
      });

      if (this.srvidDuplicate === 'N') {
        this.$dialogs.alert(this.$i18n.t('platform.alert-checkDupl'));
        return;
      }
      this.insertPlatform();
    },
    // 플랫폼 이용신청 등록
    async insertPlatform() {
      let formData = this.formData;
      const {
        data: { valid },
      } = await insertPlatform(formData);
      this.resetForm();
      if (valid) {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-success'));
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-fail'));
      }
    },
    // 폼 초기화
    resetForm() {
      this.$refs.form.reset();
      Object.assign(this.$data, this.$options.data());
      this.initLocale();
    },
  },
};
</script>
<style></style>
