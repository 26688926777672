<template>
  <ValidationProvider
    :vid="vid"
    :name="label"
    :rules="rules"
    v-slot="{ errors, invalid, validated }"
  >
    <textarea
      v-if="type === 'textarea'"
      :value="value"
      :type="type"
      class="isf"
      :class="invalid && validated ? 'error-tt' : ''"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :dataType="dataType"
      :style="inputStyle"
      :name="name"
      @input="$emit('input', $event.target.value)"
    />

    <input
      v-else
      :value="value"
      class="isf"
      :type="type"
      :class="invalid && validated ? 'error-tt' : ''"
      :placeholder="placeholder"
      :style="inputStyle"
      :maxlength="maxlength"
      :dataType="dataType"
      :name="name"
      :disabled="disabled"
      @click="$emit('click')"
      @input="$emit('input', $event.target.value)"
    />

    <label
      v-if="invalid && validated"
      :class="invalid && validated ? 'error-tt' : ''"
      :style="errorData && errorData.style"
      :for="name"
      >{{ (errorData && errorData.message) || errors[0] }}</label
    >
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
export default {
  components: { ValidationProvider },
  props: {
    vid: { type: String },
    label: { type: String },
    rules: { type: [String, Object] },
    name: { type: String },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: { type: String },
    maxlength: { type: String },
    dataType: {
      type: String,
      default: 'LITERAL',
    },
    inputStyle: { type: String },
    disabled: { type: String },
    errorData: { type: Object },
    value: {},
  },
};
</script>
<style></style>
