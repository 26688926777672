import { request } from './index';

function serviceIdDuplcation({ srvcId }) {
  return request.post(`/platform/duplicate`, { srvcId });
}

function insertPlatform(params) {
  return request.post(`/platform`, params);
}

function serviceIdUsable({ srvcId }) {
  return request.post(`/environment/service/usable`, { srvcId });
}

function insertAnalysisEnvironment(params) {
  return request.post(`/environment`, params);
}

function fetchStatusList(params) {
  return request.post(`/status/list`, params);
}

export {
  serviceIdDuplcation,
  insertPlatform,
  serviceIdUsable,
  insertAnalysisEnvironment,
  fetchStatusList,
};
