var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('SubHeader',{attrs:{"pathList":_vm.pathList,"title":_vm.title}}),_c('div',{staticClass:"contents",attrs:{"id":"contents"}},[_c('div',{staticClass:"title-sort-box"},[_c('h3',[_vm._v(_vm._s(_vm.$t('platform.register-title')))]),_c('p',{staticClass:"red-txt",domProps:{"innerHTML":_vm._s(_vm.$t('common.required-input-guide'))}})]),_c('div',{staticClass:"table-horizon st01"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"id":"InsertForm","enctype":"multipart/form-data"}},[_c('table',{staticClass:"form-md"},[_c('colgroup',[_c('col',{staticStyle:{"width":"10%"}}),_c('col',{staticStyle:{"width":"37%"}}),_c('col',{staticStyle:{"width":"16%"}}),_c('col',{staticStyle:{"width":"37%"}})]),_c('tbody',[_c('tr',[_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('platform.label-serviceId')))])]),_c('td',[_c('span',{staticClass:"isf-with"},[_c('text-input',{attrs:{"rules":{
                        required: true,
                        regex: /[0-9A-Za-z\-_]{4,20}/,
                      },"label":"Service ID","name":"srvcId","maxlength":"20","placeholder":_vm.placeholder.max10},model:{value:(_vm.formData.srvcId),callback:function ($$v) {_vm.$set(_vm.formData, "srvcId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.srvcId"}}),_c('button',{staticClass:"bt-md bt-default",attrs:{"type":"button"},on:{"click":_vm.checkServiceIdValidate}},[_vm._v(" "+_vm._s(_vm.$t('platform.btn-check-serviceId'))+" ")])],1)]),_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('platform.label-serviceName')))])]),_c('td',[_c('text-input',{attrs:{"rules":{
                      required: true,
                      regex: /[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_-]{1,30}/,
                    },"label":"Service Name","name":"srvcNm","maxlength":"50"},model:{value:(_vm.formData.srvcNm),callback:function ($$v) {_vm.$set(_vm.formData, "srvcNm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.srvcNm"}})],1)]),_c('tr',[_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('platform.label-redirectUri')))])]),_c('td',[_c('text-input',{attrs:{"rules":{
                      required: true,
                      regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                    },"label":"Redirect URI","name":"webServerRedirectUri","maxlength":"200","placeholder":_vm.placeholder.url,"dataType":"URL"},model:{value:(_vm.formData.webServerRedirectUri),callback:function ($$v) {_vm.$set(_vm.formData, "webServerRedirectUri", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.webServerRedirectUri"}})],1),_c('th',[_c('label',[_vm._v(_vm._s(_vm.$t('platform.label-company')))])]),_c('td',[_c('text-input',{attrs:{"label":"Company Name","name":"cmpnyNm","maxlength":"25"},model:{value:(_vm.formData.cmpnyNm),callback:function ($$v) {_vm.$set(_vm.formData, "cmpnyNm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.cmpnyNm"}})],1)]),_c('tr',[_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('platform.label-adminTel')))])]),_c('td',[_c('text-input',{attrs:{"rules":{
                      required: true,
                      regex: /^[0][0-9]{7,10}$/,
                    },"label":"Admin Telephone","name":"chargerTelno","maxlength":"11","placeholder":_vm.placeholder.onlyNum,"dataType":"NUMBER"},model:{value:(_vm.formData.chargerTelno),callback:function ($$v) {_vm.$set(_vm.formData, "chargerTelno", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.chargerTelno"}})],1),_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('platform.label-adminEmail')))])]),_c('td',[_c('text-input',{attrs:{"rules":{
                      required: true,
                      regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                    },"label":"Admin Email","name":"chargerEmail","maxlength":"50","dataType":"EMAIL"},model:{value:(_vm.formData.chargerEmail),callback:function ($$v) {_vm.$set(_vm.formData, "chargerEmail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.chargerEmail"}})],1)]),_c('tr',[_c('th',[_c('label',[_vm._v(_vm._s(_vm.$t('platform.label-contents')))])]),_c('td',{attrs:{"colspan":"3"}},[_c('text-input',{attrs:{"label":"Contents","name":"dc","maxlength":"200","type":"textarea"},model:{value:(_vm.formData.dc),callback:function ($$v) {_vm.$set(_vm.formData, "dc", $$v)},expression:"formData.dc"}})],1)])])])])])],1),_c('div',{staticClass:"btns-bottom"},[_c('button',{staticClass:"bt-md bt-default",attrs:{"type":"button"},on:{"click":_vm.resetForm}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.btn-cancel')))])]),_c('button',{staticClass:"bt-md bt-red",attrs:{"type":"button"},on:{"click":_vm.checkValidate}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.btn-submit')))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }